import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const ShareIcons = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: ${({ theme }) =>
    `min(calc( 100vw - ${theme.spacing.xs} ), 240px)`};
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xs};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const SocialLink = styled.a<{
  $backgroundColor: string;
  theme: ThemeType;
}>`
  display: block;
  padding: ${({ theme }) => theme.spacing.xxs};

  border-radius: ${({ theme }) => theme.button.borderRadius};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
