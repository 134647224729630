import styled from "styled-components";
import { Button, type ThemeType } from "@cruk/cruk-react-components";

export const TextInput = styled.input<{ theme: ThemeType }>`
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  background-image: none;
  border: ${({ theme }) =>
    `solid ${theme.utilities.inputBorderWidth} ${theme.colors.textInputBorder}`};
  color: ${({ theme }) => theme.colors.textDark};
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.m};
  font-weight: ${({ theme }) => theme.typography.fontWeightBase};
  padding: 6px 8px;
  width: 100%;
`;

export const CopyMessage = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  width: 100%;
`;
export const CopyButton = styled(Button)<{ theme: ThemeType }>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: none;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.textInputBorder};
  border-width: ${({ theme }) =>
    `${theme.utilities.inputBorderWidth} ${theme.utilities.inputBorderWidth} ${theme.utilities.inputBorderWidth} 0`};
`;

export const ButtonText = styled.span<{ theme: ThemeType }>`
  margin-left: ${({ theme }) => theme.spacing.xxs};
`;
