import React, { useState, useRef, type KeyboardEvent } from "react";
import { IconFa, Text } from "@cruk/cruk-react-components";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

import { isBrowser } from "@fwa/src/utils/browserUtils";

import { TextInput, CopyMessage, CopyButton, ButtonText } from "./styles";
import { Row } from "../styles";

type Props = {
  shareCopy: string;
  onClick?: () => void;
};

export const CopyToClipBoard = ({ shareCopy, onClick }: Props) => {
  const [copyMessage, setCopyMessage] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement | null>(null);

  const copyToClipboard = () => {
    if (!isBrowser) return;
    if (ref.current) {
      ref.current.select();
      ref.current.setSelectionRange(0, 99999);
    }
    const copySuccess = document.execCommand("copy");
    if (copySuccess) {
      setCopyMessage(`Copied to clipboard`);
    } else {
      setCopyMessage("Select text to copy");
    }
    setTimeout(() => {
      setCopyMessage(null);
    }, 2000);
    if (onClick) {
      onClick();
    }
  };

  return (
    <Row style={{ position: "relative" }} data-component="copy-clipboard">
      {copyMessage?.length ? (
        <CopyMessage>
          <Text textAlign="center">{copyMessage}</Text>
        </CopyMessage>
      ) : null}
      <TextInput
        type="text"
        aria-label="copy url"
        readOnly
        ref={ref}
        value={shareCopy}
      />
      <CopyButton
        appearance="secondary"
        onClick={() => {
          copyToClipboard();
        }}
        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
          if (e.key === "Enter") copyToClipboard();
        }}
        aria-label="copy url"
        role="button"
        tabIndex={0}
        data-cta-type="share-copy-clipboard"
      >
        <Row>
          <IconFa faIcon={faCopy} size="1.5rem" />
          <ButtonText>Copy</ButtonText>
        </Row>
      </CopyButton>
    </Row>
  );
};

export default CopyToClipBoard;
